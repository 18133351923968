import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
// Material UI Kit
import GridContainer from "../components/Grid/GridContainer"
import Card from "../components/Card/Card"
import CardHeader from "../components/Card/CardHeader"
import GridItem from "../components/Grid/GridItem"
// Styles
import withStyles from "@material-ui/core/styles/withStyles"
import javascriptStyles from "../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles"

const style = {
  cardBorder: {
    marginBottom: "30px",
    padding: "20px",
    alignItems: "center",
  },
  titleCenter: {
    textAlign: "center",
    padding: "20px",
  },
  message: {
    padding: "40px 90px",
    color: "#626f78",
  },
}

const TermsPage = props => {
  const { classes } = props
  return (
    <Layout>
      <SEO title="Terms of Use" />
      <GridContainer
        spacing={0}
        direction="column"
        alignItems="center"
        alignContent="center"
        justify="center"
        style={{ padding: "1.5rem 16px" }}
      >
        <Card style={style.cardBorder} stytle>
          <CardHeader
            plain
            color="primary"
            className={`${classes.textCenter} ${classes.cardLoginHeader}`}
          >
            <h5 className={classes.cardTitleWhite}>Terms of Use</h5>
          </CardHeader>
          <GridItem xs={12} sm={10} style={style.message}>
            <p align="center">
              <strong>CENTER CONSOLES ONLY</strong>®{" "}
              <strong>WEBSITE TERMS OF USE</strong>
            </p>
            <p>
              <strong>1. READ THIS:</strong>
            </p>
            <p>
              This Terms of Use Agreement (“Agreement” or “Terms of Use”) is
              made by and between Center Consoles Only, a Florida corporation,
              with offices at 4658 SW 74th Ave, Miami FL 33155 (“CCO”) and you
              (“you,” “your” or “User”). This Agreement contains the terms and
              conditions that govern your use of this Website.
            </p>
            <p>
              BY ACCESSING OR ATTEMPTING TO INTERACT WITH ANY PART OF THIS
              WEBSITE, OR OTHER CCO SOFTWARE, SERVICES, WEBSITES OR ANY OF CCO
              LICENSEES’ SERVICES (COLLECTIVELY “SERVICES”), YOU AGREE THAT YOU
              HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THIS AGREEMENT. IF
              YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT, DO NOT ACCESS OR
              USE ANY PART OF THIS WEBSITE.
            </p>
            <p>
              CCO RESERVES THE RIGHT, FROM TIME TO TIME, WITH OR WITHOUT NOTICE
              TO YOU, TO MAKE CHANGES TO THIS AGREEMENT IN CCO’S SOLE
              DISCRETION. CONTINUED USE OF ANY PART OF THIS WEBSITE CONSTITUTES
              YOUR ACCEPTANCE OF SUCH CHANGES. THE MOST CURRENT VERSION OF THIS
              AGREEMENT, WHICH SUPERSEDES ALL PREVIOUS VERSIONS, CAN BE REVIEWED
              BY CLICKING ON THE TERMS OF USE HYPERLINK LOCATED AT THE BOTTOM OF
              EVERY PAGE ON THIS WEBSITE.
            </p>
            <p>
              No implication is made that the materials published on CCO’s
              website(s) are appropriate for use outside of the United States.
              If you access this website from outside of the United States, you
              do so on your own initiative and you are responsible for
              compliance with local laws. Additionally, this website is
              published in english and we are not responsible for errors in
              translation.
            </p>
            <p>
              <strong>2. ACCESS TO THIS SITE</strong>
            </p>
            <p>
              To access this site or some of the resources it offers, you may be
              asked to provide certain registration details or other
              information. It is a condition of your use of this site that all
              the information you provide on this site will be correct, current,
              and complete. If CCO believes the information you provide is not
              correct, current, or complete, CCO has the right to refuse you
              access to this site or any of its resources, and to terminate or
              suspend your access at any time.
            </p>
            <p>
              <strong>3. RESTRICTIONS ON USE</strong>
            </p>
            <p>
              You may use this site for purposes expressly permitted by this
              site. As a condition of your use of CCO’s website(s), you warrant
              to CCO that you will not use the website(s) for any purpose that
              is unlawful or prohibited by these terms, conditions, and notices.
              For example, you may not (and may not authorize any party to) (i)
              co-brand this site, or (ii) frame this site, or (iii) download any
              content from this site (other than as provided by these terms)
              without the express prior written permission of an authorized
              representative of CCO. &nbsp;For purposes of these Terms of Use,
              co-branding means to display a name, logo, trademark, or other
              means of attribution or identification of any party in such a
              manner as is reasonably likely to give a user the impression that
              such other party has the right to display, publish, or distribute
              this site or content accessible within this site. You agree to
              cooperate with CCO to prevent or remedy any unauthorized use. In
              addition, you may not use CCO’s website(s) in any manner which
              could disable, overburden, damage, or impair the website(s) or
              interfere with any other party’s use and enjoyment of the
              website(s). You may not obtain or attempt to obtain any materials,
              content, or information through any means not intentionally made
              available or provided for through the website(s).
            </p>
            <p>
              <strong>
                4.<strong>&nbsp; </strong>PERSONAL AND NON-COMMERCIAL USE
                LIMITATION
              </strong>
            </p>
            <p>
              CCO’s website(s) are for your personal and non-commercial use,
              unless otherwise specified. You may not use this site for any
              other purpose, including any commercial purpose, without CCO’s
              express prior written consent. You may not modify, copy,
              distribute, display, send, perform, reproduce, publish, license,
              create derivative works from, transfer, or sell any information,
              content, software, products or services obtained from or otherwise
              connected to CCO’s website(s), unless expressly permitted by these
              terms.
            </p>
            <p>
              <strong>
                5.<strong>&nbsp; </strong>PROPRIETARY INFORMATION
              </strong>
            </p>
            <p>
              The material and content accessible from this site, and any other
              website owned, operated, licensed, or otherwise controlled by CCO
              (the “Content”) is the proprietary information of CCO or the party
              that provided or licensed the Content to CCO whereby such
              providing party retains all right, title, and interest in the
              Content. Accordingly, the Content may not be copied, distributed,
              republished, uploaded, posted, or transmitted in any way without
              the prior written consent of CCO, except that you may print out a
              copy of the Content solely for your personal use, and you may
              re-post a single image and up to five hundred (500) words of text
              from any article if such posting provides a right of attribution
              to Center Consoles Only, and promotes the article on other
              websites, including social media sites. &nbsp;In doing so, you may
              not remove or alter, or cause to be removed or altered, any
              copyright, trademark, trade name, service mark, or any other
              proprietary notice or legend appearing on any of the Content.
              &nbsp;Modification or use of the Content except as expressly
              provided in these Terms of Use violates CCO’s intellectual
              property rights. Neither title nor intellectual property rights
              are transferred to you by access to this site.
            </p>
            <p>
              From time to time, the website will utilize various plugins or
              widgets to allow sharing of content via social media channels,
              email or other methods. Use of these plugins or widgets does not
              constitute any waiver of CCO’s intellectual property rights. Such
              use is a limited license to republish the content on the approved
              social media channels, with full credit to the website.
            </p>
            <p>
              <strong>
                6.<strong>&nbsp; </strong>LINKS TO THIRD-PARTY WEBSITES
              </strong>
            </p>
            <p>
              This site may link to other sites which are not maintained by, or
              related to, CCO.&nbsp;You represent and warrant that you have read
              and agree to be bound by all applicable Terms of Use and policies
              for any third-party websites. Links to such sites are provided as
              a service to users and are not sponsored by or affiliated with
              this site or CCO.&nbsp; CCO has not reviewed any or all of such
              sites and is not responsible for the content of those sites. Links
              are to be accessed at the user’s own risk, and CCO makes no
              representations or warranties about the content, completeness or
              accuracy of the sites linked to or from this site. You expressly
              hold CCO harmless from any and all liability related to your use
              of a third-party website. CCO provides links as a convenience, and
              the inclusion of any link to a third-party site does not imply
              endorsement by CCO of that site or any association with its
              operators.
            </p>
            <p>
              <strong>
                7.<strong>&nbsp; </strong>USE OF COMMUNICATION SERVICES
              </strong>
            </p>
            <p>
              CCO’s websites may contain comment boxes, forums, bulletin board
              services, chat areas, message boards, news groups, communities,
              personal web pages, calendars, and/or other message or
              communication facilities designed to allow you to communicate with
              the Internet community or with a group (collectively,
              “Communications Services”). You agree to use the Communication
              Services only to post, send and receive messages and content that
              are proper and related to the particular Communication Service.
              Users agree to adhere to this Terms of Use agreement when using
              CCO’s Communication Services.
            </p>
            <p>
              When using the Communication Services, you agree that you will not
              post, send, submit, publish, or transmit in connection with this
              site any material that:
            </p>
            <ol>
              <li>
                You do not have the right to post, including proprietary
                material of any third party, such as files containing software
                or other material protected by intellectual property laws (or by
                rights of privacy or publicity);
              </li>
              <li>
                Advocates illegal activity or discusses an intent to commit an
                illegal act;
              </li>
              <li>Is vulgar, obscene, pornographic, or indecent;</li>
              <li>Does not pertain directly to this site;</li>
              <li>
                Threatens or abuses others, libels, defames, invades privacy,
                stalks, is obscene, pornographic, racist, abusive, harassing,
                threatening or offensive;
              </li>
              <li>
                Seeks to exploit or harm children by exposing them to
                inappropriate content, asking for personally identifiable
                details or otherwise;
              </li>
              <li>
                Harvests or otherwise collects information about others,
                including e-mail addresses, without their consent;
              </li>
              <li>Violates any law or may be considered to violate any law;</li>
              <li>
                Impersonates or misrepresents your connection to any other
                entity or person or otherwise manipulates headers or identifiers
                to disguise the origin of the content;
              </li>
              <li>
                Falsifies or deletes any author attributions, legal or other
                proper notices or proprietary designations or labels of the
                origin or source of software or other material contained in a
                file that is permissibly uploaded;
              </li>
              <li>
                Advertises any commercial endeavor (e.g., offering for sale
                products or services) or otherwise engages in any commercial
                activity (e.g., conducting raffles or contests, displaying
                sponsorship banners, and/or soliciting goods or services) except
                as may be specifically authorized on this site;
              </li>
              <li>Solicits funds, advertisers or sponsors;</li>
              <li>
                Includes programs that contain viruses, worms and/or Trojan
                horses or any other computer code, files or programs designed to
                interrupt, destroy or limit the functionality of any computer
                software or hardware or telecommunications;
              </li>
              <li>
                Disrupts the normal flow of dialogue, causes a screen to scroll
                faster than other users are able to type, or otherwise act in a
                way which affects the ability of other people to engage in real
                time activities via this site;
              </li>
              <li>Includes MP3 format files;</li>
              <li>
                Amounts to a pyramid or other like scheme, including contests,
                chain letters, and surveys;
              </li>
              <li>
                Disobeys any policy or regulations including any code of conduct
                or other guidelines, established from time to time regarding use
                of this site or any networks connected to this site; or
              </li>
              <li>
                Contains hyper-links to other sites that contain content that
                falls within the descriptions set forth above.
              </li>
            </ol>
            <p>
              CCO reserves the right to monitor use of this site to determine
              compliance with these Terms of Use, as well as the right to remove
              or refuse any information for any reason. CCO reserves the right
              to terminate your access to any or all of the Communication
              Services at any time without notice for any reason whatsoever. CCO
              also reserves the right at all times to disclose any information
              as necessary to satisfy any applicable law, regulation, legal
              process or governmental request, or to edit, refuse to post or to
              remove any information or materials, in whole or in part, in its
              sole discretion. Materials uploaded to a Communication Service may
              be subject to posted limits on use, reproduction and/or
              dissemination and you are responsible for abiding by such
              limitations with respect to your submissions, including any
              downloaded materials.
            </p>
            <p>
              Notwithstanding these rights, you remain solely responsible for
              the content of your submissions. You acknowledge and agree that
              neither CCO nor any third party that provides Content to CCO will
              assume or have any liability for any action or inaction by CCO or
              such third party with respect to any submission. CCO cautions you
              against giving out any personally identifying information about
              yourself in any Communication Service. CCO does not control or
              endorse the content, messages or information found in any
              Communication Service and, consequently, CCO specifically
              disclaims any liability with respect to the Communication Services
              and any actions resulting from your participation in any
              Communication Service. Managers and hosts are not authorized CCO
              spokespersons, and their views do not necessarily reflect those of
              CCO.
            </p>
            <p>
              <strong>
                8.<strong>&nbsp; </strong>SUBMISSIONS
              </strong>
            </p>
            <p>
              Unless you and CCO enter into a separate written contract for use
              of your Content that states otherwise, you hereby grant to CCO the
              royalty-free, perpetual, irrevocable, worldwide, non-exclusive
              right and license to use, reproduce, modify, adapt, publish,
              translate, create derivative works from, distribute, perform, and
              display all content, remarks, suggestions, ideas, graphics, or
              other information communicated to CCO through this site (together,
              the “Submission”), and to incorporate any Submission in other
              works in any form, media, or technology now known or later
              developed. CCO will not be required to treat any Submission as
              confidential, and may use any Submission in its business
              (including without limitation, for products or advertising)
              without incurring any liability for royalties or any other
              consideration of any kind, and will not incur any liability as a
              result of any similarities that may appear in future CCO
              operations.
            </p>
            <p>
              CCO will treat any personal information that you submit through
              this site in accordance with its Privacy Policy{" "}
              <Link to={"/privacy"}>Privacy Policy</Link>
            </p>
            <p>
              <strong>
                9.<strong>&nbsp; </strong>DISCLAIMER
              </strong>
            </p>
            <p>
              CCO has made every effort to ensure that all information on the
              website has been tested for accuracy. CCO make no guarantees
              regarding the results that you will see from using the information
              provided on the website.
            </p>
            <p>
              The website was developed strictly for informational purposes. You
              understand and agree that you are fully responsible for your use
              of the information provided on the website. CCO makes no
              representations, warranties, or guarantees. You understand that
              results may vary from person to person. CCO assumes no
              responsibility for errors or omissions that may appear on the
              website.
            </p>
            <p>
              You understand that CCO cannot and does not guarantee or warrant
              that files available for downloading from the Internet will be
              free of viruses, worms, Trojan horses or other code that may
              manifest contaminating or destructive properties. The website is
              provided on an “as is” and “as available” basis without any
              representations or warranties, expressed or implied. You are
              responsible for implementing sufficient procedures and checkpoints
              to satisfy your particular requirements for accuracy of data input
              and output, and for maintaining a means external to this site for
              any reconstruction of any lost data. CCO does not assume any
              responsibility or risk for your use of the Internet.
            </p>
            <p>
              The Content is not necessarily complete and up-to-date and should
              not be used to replace any written reports, statements, or notices
              provided by CCO.
            </p>
            <p>
              YOUR USE OF THIS SITE IS AT YOUR OWN RISK. THE CONTENT IS PROVIDED
              AS IS AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR
              IMPLIED. CCO DISCLAIMS ALL WARRANTIES, INCLUDING ANY IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE, OR NON-INFRINGEMENT. CCO DOES NOT WARRANT THAT THE
              FUNCTIONS OR CONTENT CONTAINED IN THIS SITE WILL BE UNINTERRUPTED
              OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS SITE
              OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS. CCO DOES NOT WARRANT OR MAKE ANY
              REPRESENTATION REGARDING USE, OR THE RESULT OF USE, OF THE CONTENT
              IN TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE. THE CONTENT MAY
              INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS, AND CCO
              MAY MAKE CHANGES OR IMPROVEMENTS AT ANY TIME. YOU, AND NOT CCO,
              ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR
              CORRECTION IN THE EVENT OF ANY LOSS OR DAMAGE ARISING FROM THE USE
              OF THIS SITE OR ITS CONTENT. CCO MAKES NO WARRANTIES THAT YOUR USE
              OF THE CONTENT WILL NOT INFRINGE THE RIGHTS OF OTHERS AND ASSUMES
              NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH
              CONTENT.
            </p>
            <p>
              All of the information in this site, whether historical in nature
              or forward-looking, speaks only as of the date the information is
              posted on this site, and CCO does not undertake any obligation to
              update such information after it is posted or to remove such
              information from this site if it is not, or is no longer accurate
              or complete.
            </p>
            <p>
              <strong>
                10.<strong>&nbsp; </strong>LIMITATION ON LIABILITY
              </strong>
            </p>
            <p>
              CCO, ITS SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS,
              CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS WILL
              NOT BE LIABLE FOR ANY INCIDENTAL, DIRECT, INDIRECT, PUNITIVE,
              ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER DAMAGES,
              INCLUDING LOSS OF REVENUE OR INCOME, PAIN AND SUFFERING, EMOTIONAL
              DISTRESS, OR SIMILAR DAMAGES, EVEN IF CCO HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES.
            </p>
            <p>
              <strong>
                11.<strong>&nbsp; </strong>TERMINATION OR RESTRICTION OF ACCESS
              </strong>
            </p>
            <p>
              CCO reserves the right, in its sole discretion, to terminate your
              access to any or all of CCO’s websites and the related services or
              any portion thereof at any time, without notice.
            </p>
            <p>
              <strong>
                12.<strong>&nbsp; </strong>INDEMNITY
              </strong>
            </p>
            <p>
              You will indemnify and hold CCO, its subsidiaries, affiliates,
              licensors, content providers, service providers, employees,
              agents, officers, directors, and contractors (the “Indemnified
              Parties”) harmless from any breach of these Terms of Use by you,
              including any use of Content other than as expressly authorized in
              these Terms of Use. You agree that the Indemnified Parties will
              have no liability in connection with any such breach or
              unauthorized use, and you agree to indemnify any and all resulting
              loss, damages, judgments, awards, costs, expenses, and attorneys’
              fees of the Indemnified Parties in connection therewith. You will
              also indemnify and hold the Indemnified Parties harmless from and
              against any claims brought by third parties arising out of your
              use of the information accessed from this site.
            </p>
            <p>
              <strong>
                13.<strong>&nbsp; </strong>TRADEMARKS AND COPYRIGHTS
              </strong>
            </p>
            <p>
              The trademarks, trade names, service marks, and logos
              (“Trademarks”) appearing on this Website, including without
              limitation CENTER CONSOLES ONLY®&nbsp;and/or its distinctive
              logo.&nbsp; All other content on this Website (“Copyrights”),
              including all page headers, custom graphics, button icons, and
              scripts are copyrighted works of CCO, and may not be copied,
              imitated or used, in whole or in part, without the prior written
              permission of CCO.&nbsp; From time to time, the website will
              legally utilize intellectual property owned by third parties
              related to our services.&nbsp; The rights in any third party
              trademarks or copyrighted works on this Website are retained by
              their respective owners.&nbsp; Nothing in this Agreement shall
              confer any right of ownership of any of the Trademarks or
              Copyrights to you.&nbsp; Further, nothing in this Agreement shall
              be construed as granting, by implication, estoppel or otherwise,
              any license or right to use any Trademark or Copyright without the
              express written permission of CCO.&nbsp; The misuse of the
              Trademark or Copyrighted works displayed in this site, or any
              other content on the site, is strictly prohibited and may expose
              you to liability.
            </p>
            <p>
              All contents of CCO’s websites are: Copyright © SOCIAL MEDIA
              EXAMINER. &nbsp;All rights reserved.
            </p>
            <p>
              <strong>
                14.<strong>&nbsp; </strong>COPYRIGHT INFRINGEMENT
              </strong>
            </p>
            <p>
              <strong>Notice and Takedown Procedure</strong>
            </p>
            <p>
              CCO expeditiously responds to valid notices of copyright
              infringement that adhere to the requirements set forth in the
              Digital Millennium Copyright Act (DMCA). If you believe that your
              work has been copied in a way that constitutes copyright
              infringement, you may provide CCO’s Designated Agent (listed
              below) with a notice that contains all six points enumerated below
              (preferably via email).
            </p>
            <p>
              Upon receipt of a valid notice, CCO will remove or disable access
              to the allegedly infringing content as well as make a good-faith
              attempt to contact the owner or administrator of the affected
              content so they may counter-notice pursuant to Sections 512(g)(2)
              and (3) of the DMCA.
            </p>
            <ol>
              <li>
                An electronic or physical signature of the person authorized to
                act on behalf of the owner of the copyright interest;
              </li>
              <li>
                A description of the copyrighted work that you claim has been
                infringed upon;
              </li>
              <li>
                A description of where the material that you claim is infringing
                is located on the site, including the URL, if applicable;
              </li>
              <li>Your address, telephone number, and e-mail address;</li>
              <li>
                A statement by you that you have a good-faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law; and,
              </li>
              <li>
                A statement by you, made under penalty of perjury, that the
                above information in your notice is accurate and that you are
                the copyright owner or authorized to act on the copyright
                owner’s behalf.
              </li>
            </ol>
            <p>
              Be aware that a notice must contain all six points for CCO to take
              action. All other notices will be ignored.
            </p>
            <p>
              <strong>Designated Agent:</strong>
            </p>
            <p>CCO’s Designated Agent can be can be contacted at:</p>
            <p>
              DMCA Designated Agent
              <br />
              Center Consoles Only
              <br />
              4658 SW 74th Ave
              <br />
              Miami, FL 33155
              <br />
              Phone: 305-282-2680
              <br />
              e-mail:{" "}
              <a href="mailto:support@centerconsolesonly.net">
                support@centerconsolesonly.net
              </a>
            </p>
            <p>
              <strong>Counter-Notification Procedure</strong>
            </p>
            <p>
              The provider of the allegedly infringing content may make a
              counter-notification pursuant to sections 512(g)(2) and (3) of the
              DMCA. To file a counter-notification with CCO please provide CCO’s
              Designated Agent (listed above) with the following information
              (preferably via email):
            </p>
            <ol>
              <li>
                Identification of the material that has been removed or to which
                access has been disabled and the location at which the material
                appeared before it was removed or access to it was disabled;
              </li>
              <li>Your name, address, and telephone number;</li>
              <li>
                The following statement: “I consent to the jurisdiction of
                Federal District Court for the [insert the federal judicial
                district in which your address is located]”;
              </li>
              <li>
                The following statement: “I will accept service of process from
                [insert the name of the person who submitted the infringement
                notification] or his/her agent”;
              </li>
              <li>
                The following statement: “I swear, under penalty of perjury,
                that I have a good faith belief that the affected material was
                removed or disabled as a result of a mistake or
                misidentification of the material to be removed or disabled”;
                and
              </li>
              <li>Your signature, in physical or electronic form.</li>
            </ol>
            <p>
              Upon receipt of a counter-notification containing all six points,
              CCO will promptly provide the person who provided the original
              takedown notification with a copy of the counter-notification, and
              inform that person that CCO will replace the removed material or
              cease disabling access to it in 10 business days.
            </p>
            <p>
              Finally, if CCO’s Designated Agent receives notification from the
              person who submitted the original takedown notification within 14
              days of receipt of the counter-notification that an action has
              been filed seeking a court order to restrain the alleged infringer
              from engaging in infringing activity relating to the material on
              its system, then CCO will once again remove the file from its
              system.
            </p>
            <p>
              <strong>Repeat Infringers</strong>
            </p>
            <p>
              In accordance with Section 512(i)(1)(a) of the DMCA, CCO will, in
              appropriate circumstances, disable and/or terminate the accounts
              of users who are repeat infringers.
            </p>
            <p>
              <strong>Accommodation of Standard Technical Measures.</strong>
            </p>
            <p>
              It is CCO’s policy to accommodate and not interfere with standard
              technical measures used by copyright owners to identify or protect
              copyrighted works that CCO determines are reasonable under the
              circumstances.
            </p>
            <p>
              <strong>
                15.<strong>&nbsp; </strong>SECURITY
              </strong>
            </p>
            <p>
              Any passwords used for this site are for individual use only. You
              will be responsible for the security of your password (if any).
              CCO will be entitled to monitor your password and, at its
              discretion, require you to change it. If you use a password that
              CCO considers insecure, CCO will be entitled to require the
              password to be changed and/or terminate your account.
            </p>
            <p>
              You are prohibited from using any services or facilities provided
              in connection with this site to compromise security or tamper with
              system resources and/or accounts. The use or distribution of tools
              designed for compromising security (e.g., password guessing
              programs, cracking tools or network probing tools) is strictly
              prohibited. If you become involved in any violation of system
              security, CCO reserves the right to release your details to system
              administrators at other sites in order to assist them in resolving
              security incidents. CCO reserves the right to investigate
              suspected violations of these Terms of Use.
            </p>
            <p>
              CCO reserves the right to fully cooperate with any law enforcement
              authorities or court order requesting or direction CCO to disclose
              the identity of anyone posting any e-mail messages, or publishing
              or otherwise making available any materials that are believed to
              violate these Terms of Use. BY ACCEPTING THIS AGREEMENT YOU WAIVE
              AND HOLD HARMLESS CCO FROM ANY CLAIMS RESULTING FROM ANY ACTION
              TAKEN BY CCO DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR
              FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY
              EITHER CCO OR LAW ENFORCEMENT AUTHORITIES.
            </p>
            <p>
              <strong>
                16.<strong>&nbsp; </strong>MISCELLANEOUS
              </strong>
            </p>
            <p>
              These Terms of Use will be governed and interpreted pursuant to
              the laws of Florida, United States of America, notwithstanding any
              principles of conflicts of law. You specifically consent to
              personal jurisdiction in Florida in connection with any dispute
              between you and CCO arising out of these Terms of Use or
              pertaining to the subject matter hereof. The parties to these
              Terms of Use each agree that the exclusive venue for any dispute
              between the parties arising out of these Terms of Use will be in
              the state and federal courts in Miami-Dade County, California.
            </p>
            <p>
              If any part of these Terms of Use is unlawful, void or
              unenforceable, that part will be deemed severable and will not
              affect the validity and enforceability of any remaining
              provisions.
            </p>
            <p>
              You agree that no joint venture, partnership, employment, or
              agency relationship exists between you and CCO as a result of this
              agreement or use of CCO’s websites.
            </p>
            <p>
              These Terms of Use constitute the entire agreement among the
              parties relating to this subject matter and supersedes all prior
              or contemporaneous communications and proposals, whether
              electronic, oral or written between the user and CCO with respect
              to CCO’s websites.
            </p>
            <p>
              A printed version of this agreement and of any notice given in
              electronic form shall be admissible in judicial or administrative
              proceedings based upon or relating to this agreement to the same
              extent and subject to the same conditions as other business
              documents and records originally generated and maintained in
              printed form.
            </p>
            <p>
              The Terms of Use may not be assigned by you without our prior
              written consent, however, the Terms of Use may be assigned by us
              in our sole discretion.
            </p>
            <p>
              Notwithstanding the foregoing, any additional terms and conditions
              on this site will govern the items to which they pertain.
            </p>
            <p>
              CCO may revise these Terms of Use at any time by updating this
              posting.
            </p>
          </GridItem>
        </Card>
      </GridContainer>
    </Layout>
  )
}
export default withStyles(javascriptStyles)(TermsPage)
